import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import { MantineProvider } from "@mantine/core";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { NhostProvider } from "@nhost/react";
import { NotificationsProvider } from '@mantine/notifications';
import { NhostClient } from '@nhost/nhost-js'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const nhost = new NhostClient({
  subdomain: 'uipnxcvsuszhtkqvvmxy',
  region: 'us-east-1'
})


root.render(
  <React.StrictMode>
    <MantineProvider>
      <NotificationsProvider>
        <NhostProvider nhost={nhost}>
          <BrowserRouter>
            <App/>
          </BrowserRouter>
        </NhostProvider>
      </NotificationsProvider>
    </MantineProvider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
