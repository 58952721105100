import { Button, LoadingOverlay, PasswordInput, Stack, TextInput, Title } from "@mantine/core";
import { IconCheck, IconLock, IconMail, IconX } from "@tabler/icons";
import React, { useEffect } from "react";
import { useResetPassword, useSignInEmailPassword } from "@nhost/react";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";


export function PasswordReset() {
  const {
    resetPassword,
    isSent,
    isLoading,
    isError,
    error
  } = useResetPassword({
    redirectTo: 'http://localhost:3000/change-password'
  });

  const form = useForm({
    initialValues: {
      email: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email')
    }
  })

  async function handleSubmit({ email }: typeof form.values) {
    await resetPassword(email);
  }

  useEffect(() => {
    if (isSent) {
      showNotification({
        message: "The password reset link has been sent to your email",
        icon: <IconCheck />
      })
    }

    if (isError) {
      showNotification({
        message: error?.message,
        icon: <IconX />,
        color: "red"
      })
    }
  }, [isSent, isError])

  return <form onSubmit={form.onSubmit(handleSubmit)}>
    <Stack>
      <Title align={"center"} order={2}>Reset password</Title>
      <LoadingOverlay visible={isLoading}/>
      <TextInput {...form.getInputProps('email')} icon={<IconMail/>} placeholder={"Email"} type={"email"}></TextInput>
      <Button type={"submit"}>Reset password</Button>
    </Stack>
  </form>
}
