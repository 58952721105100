import { Button, LoadingOverlay, Notification, PasswordInput, Stack, TextInput } from "@mantine/core";
import { IconBrandGoogle, IconCheck, IconExclamationMark, IconLock, IconMail, IconX } from "@tabler/icons";
import React, { useEffect } from "react";
import { useNhostClient, useSignInEmailPassword, useSignUpEmailPassword } from "@nhost/react";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";


export function SignUp() {

  const {
    signUpEmailPassword,
    needsEmailVerification,
    isLoading,
    isSuccess,
    isError,
    error
  } = useSignUpEmailPassword();

  const nhost = useNhostClient();
  const form = useForm({
    initialValues: {
      email: "",
      password: ""
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (x) => x.length < 6 ? "Password must be at least 6 characters long" : null
    }
  })

  async function handleSubmit({ email, password }: typeof form.values) {
    await signUpEmailPassword(email, password);
  }

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        message: "You have been successfully registered",
        icon: <IconCheck/>
      })
    }

    if (isError) {
      showNotification({
        message: error?.message,
        icon: <IconX/>,
        color: "red"
      })
    }
  }, [isSuccess, isError])

  return <form onSubmit={form.onSubmit(handleSubmit)}>
    <Stack>
      <LoadingOverlay visible={isLoading}/>
      <TextInput {...form.getInputProps('email')} icon={<IconMail/>} placeholder={"Email"} type={"email"}></TextInput>
      <PasswordInput {...form.getInputProps('password')} icon={<IconLock/>} placeholder={"Password"}></PasswordInput>
      <Button type={"submit"}>Sign Up</Button>
      <Button onClick={() => nhost.auth.signIn({
        provider: "google",
      })} leftIcon={<IconBrandGoogle/>}>Continue with Google</Button>
    </Stack>
  </form>
}
