import { Button, LoadingOverlay, PasswordInput, Stack, TextInput, Title } from "@mantine/core";
import { IconCheck, IconLock, IconMail, IconPassword, IconX } from "@tabler/icons";
import React, { useEffect } from "react";
import { useChangePassword, useResetPassword, useSignInEmailPassword } from "@nhost/react";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";


export function ChangePassword() {
  const {
    changePassword,
    isSuccess,
    isError,
    error
  } = useChangePassword();

  const form = useForm({
    initialValues: {
      password: "",
    },
    validate: {
      password: (x) => x.length < 6 ? "Password must be at least 6 characters long" : null
    }
  })

  async function handleSubmit({ password }: typeof form.values) {
    await changePassword(password);
  }

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        message: "The password has been changed successfully",
        icon: <IconCheck />
      })
    }

    if (isError) {
      showNotification({
        message: error?.message,
        icon: <IconX />,
        color: "red"
      })
    }
  }, [isSuccess, isError])

  return <form onSubmit={form.onSubmit(handleSubmit)}>
    <Stack>
      <Title align={"center"} order={2}>Change password</Title>
      <TextInput {...form.getInputProps('password')} icon={<IconLock/>} placeholder={"New password"} type={"password"}></TextInput>
      <Button type={"submit"}>Change password</Button>
    </Stack>
  </form>
}
