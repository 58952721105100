import React from 'react';
import {
  Button,
  Group,
  Modal,
  AppShell, Header, Container, Flex, Menu, ActionIcon
} from '@mantine/core';
import { Route, Routes, useNavigate } from "react-router-dom";
import Main from "./pages/Main";
import { SignUp } from "./components/SignUp";
import { SignIn } from "./components/SignIn";
import { PasswordReset } from "./pages/PasswordReset";
import { SignedIn, SignedOut, useSignOut, useUserData } from "@nhost/react";
import { Avatar } from '@mantine/core';
import { ChangePassword } from "./pages/ChangePassword";
import { IconCurrencyDollar, IconDoorExit } from "@tabler/icons";


function App() {
  const userData = useUserData();
  const navigate = useNavigate();
  const { signOut } = useSignOut();

  return (
    <AppShell
      padding="lg"
      header={<Header height={60} p="xs">
        <Container size={"sm"}>
          <Flex justify={"space-between"}>
            <Button onClick={() => navigate('/')} variant={"gradient"}>Perfect Essay</Button>
            <Group spacing={"xs"} position={"right"}>
              <SignedOut>
                <Button size={"sm"} onClick={() => navigate('/sign-in')} variant={"light"}>Sign In</Button>
                <Button size={"sm"} onClick={() => navigate('/sign-up')}>Sign Up</Button>
              </SignedOut>
              <SignedIn>
                <Menu>
                  <Menu.Target>
                    <ActionIcon>
                      <Avatar sx={{
                        cursor: "pointer"
                      }}><img src={userData?.avatarUrl}/></Avatar>
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item onClick={() => signOut()}
                               icon={<IconCurrencyDollar size={14}/>}>Subscription</Menu.Item>
                    <Menu.Item onClick={() => signOut()} icon={<IconDoorExit size={14}/>}>Sign Out</Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </SignedIn>
            </Group>
          </Flex>
        </Container>
      </Header>}
      styles={(theme) => ({
        main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
      })}
    >
      <Container size={"sm"}>
        <Routes>
          <Route path={'/'} element={<Main/>}>
            <Route path={'sign-in'}
                   element={<Modal title={"Sign In"} opened={true} withCloseButton onClose={() => navigate(-1)}>
                     <SignIn/>
                   </Modal>}/>
            <Route path={'sign-up'}
                   element={<Modal title={"Sign Up"} opened={true} withCloseButton onClose={() => navigate(-1)}>
                     <SignUp/>
                   </Modal>}/>
          </Route>
          <Route path={'/reset-password'} element={<PasswordReset/>}/>
          <Route path={'/change-password'} element={<ChangePassword/>}/>
        </Routes>
      </Container>
    </AppShell>
  );
}

export default App;
