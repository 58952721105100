import { Button, LoadingOverlay, Modal, PasswordInput, Stack, TextInput, Text } from "@mantine/core";
import { IconCheck, IconLock, IconMail, IconX, IconBrandGoogle } from "@tabler/icons";
import React, { useEffect } from "react";
import { useSignInEmailPassword } from "@nhost/react";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { Link, useNavigate } from "react-router-dom";


export function SignIn() {
  const {
    signInEmailPassword,
    needsEmailVerification,
    isLoading,
    isSuccess,
    isError,
    error
  } = useSignInEmailPassword();

  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      email: "",
      password: ""
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (x) => x.length < 6 ? "Password must be at least 6 characters long" : null
    }
  })

  async function handleSubmit({ email, password }: typeof form.values) {
    await signInEmailPassword(email, password);
  }

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        message: "You have been successfully signed in.",
        icon: <IconCheck/>
      })
      navigate(-1)
    }

    if (needsEmailVerification) {
      showNotification({
        message: "You must verify your email first",
        icon: <IconX/>,
        color: "red"
      })
    }

    if (isError) {
      showNotification({
        message: error?.message,
        icon: <IconX/>,
        color: "red"
      })
    }
  }, [isSuccess, isError])

  return <form onSubmit={form.onSubmit(handleSubmit)}>
    <Stack>
      <LoadingOverlay visible={isLoading}/>
      <TextInput {...form.getInputProps('email')} icon={<IconMail/>} placeholder={"Email"} type={"email"}></TextInput>
      <PasswordInput {...form.getInputProps('password')} icon={<IconLock/>} placeholder={"Password"}></PasswordInput>
      <Button type={"submit"}>Sign In</Button>
      <Button leftIcon={<IconBrandGoogle />}>Continue with Google</Button>

      <Text size={"sm"} align={"center"}><Link to={"/reset-password"}>Forgot password?</Link></Text>
    </Stack>
  </form>
}
