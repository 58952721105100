import React, { useState } from 'react';
import {
  Button,
  Flex, Group,
  LoadingOverlay, Modal, NumberInput,
  Paper,
  Select,
  Stack,
  Text,
  Textarea,
  TextInput, Stepper, Title, Overlay, useMantineTheme
} from '@mantine/core';
import {
  IconArrowLeft,
  IconArrowRight, IconDownload, IconRotateClockwise
} from "@tabler/icons";
import { useForm } from '@mantine/form';
import { RichTextEditor } from "@mantine/rte";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import * as jsPDF from "jspdf";
import { SignedIn, SignedOut, useAuthenticated } from "@nhost/react";
import axios from "axios";



function App() {
  const [loading, setLoading] = useState(false);
  const [markup, setMarkup] = useState("");

  const form = useForm({
    initialValues: {
      title: '',
      tone: 'casual',
      outline: "",
      talkingPoints: "",
      style: "",
      essay: "",
      wordCount: 1000,
      step: null
    }, validate: {
      title: (x) => !x
    }
  });


  function getPrompt(step: number, f: typeof form.values) {
    const prompt = `Generate an outline for ${f.wordCount}-word long essay with title '${f.title}' in ${f.tone} tone`
    switch (step) {
      case 1:
        return prompt;
      case 2:
        return `We are writing a ${f.wordCount}-word long essay on the topic ${f.title}\n\n Here is the outline ${f.outline}\n\n ${f.style ? `Now fill in the outline in style of ${f.style}: ` : "Now fill in the outline of the essay"}`
    }
    return prompt;
  }

  async function getGPTPromptResponse(prompt: string): Promise<string> {

    const response = await axios.post('https://us-central1-lofty-sonar-371917.cloudfunctions.net/gpt3', {
      prompt,
    })

    return response.data?.choices?.[0].text?.trim().replace(/^:/, '').trim() ?? "";
  }

  async function handleFormSubmit(f: typeof form.values) {
    setLoading(true);

    const response = await getGPTPromptResponse(getPrompt(step, f))

    const stepToField = ["ideas", "outline", "talkingPoints", "essay"]
    form.setFieldValue(stepToField[step], response)
    setLoading(false)
  }

  const examples = [
    {
      headline: "Write essay how US profited from World War 2",
      title: "How US profited from World War 2"
    },
    {
      headline: "Write essay about origins of mathematics",
      title: "origins of mathematics"
    }
  ]

  const tones = [{
    label: 'Witty',
    value: "witty"
  }, { label: "Formal", value: "formal" }, { label: "Casual", value: "casual" }
  ]

  const styles = [{
    value: "Albert Einstein", label: "Albert Einstein"
  }, {
    value: "Oscar Wilde", label: "Oscar Wilde"
  }, {
    value: "George Orwell", label: "George Orwell"
  }, {
    value: "", label: "Don't apply style"
  }]

  const [step, setStep] = useState(0);
  const [opened, { close, open }] = useDisclosure(false);
  const [opened1, { close: close1, open: open1 }] = useDisclosure(false);


  function IdeaGenerator() {
    const [topic, setTopic] = useState("");
    const [loading, setLoading] = useState(false);
    const [titleIdeas, setTitleIdeas] = useState<string[]>([]);

    async function generateTitles() {
      setLoading(true)
      const response = await getGPTPromptResponse(`Write 5 creative essay ideas about ${topic}`)
      setTitleIdeas(response.split("\n").filter(x => !!x).map(x => x.replace(/^\d+\. /, '')))
      setLoading(false)
    }

    return <Modal size={"lg"} title={"Essay title generator"} opened={opened} onClose={close} withCloseButton>
      <Stack>
        <LoadingOverlay visible={loading}></LoadingOverlay>
        <Text align={"center"} color={"dimmed"} size={"md"}>Roughly describe what you want to write about</Text>
        <TextInput onChange={(e) => setTopic(e.currentTarget?.value ?? "")}
                   value={topic}
                   placeholder={"I.e. 'mathematics essay' or 'history essay', or just keywords"}></TextInput>
        {titleIdeas.length > 0 &&
            <>
                <Text align={"center"} color={"dimmed"} size={"md"}>Here some title ideas for you</Text>
              {titleIdeas.map((x, y) => (
                <Paper key={y} withBorder onClick={() => {
                  form.setFieldValue("title", x);
                  close()
                }} p={"xs"} sx={(theme) => (
                  {
                    cursor: "pointer",
                    ":hover": {
                      opacity: 0.5,
                    }
                  })}><Text size={"sm"}>{x}</Text></Paper>))}
                <Text color={"dimmed"} align={"center"} size={"md"}>Don't like anything?</Text>
            </>}
        <Button
          onClick={() => generateTitles()}>{titleIdeas.length ? "Regenerate" : "Generate"} titles</Button>
      </Stack>
    </Modal>
  }

  async function generateMarkup() {
    setLoading(true)
    setMarkup(await getGPTPromptResponse(
      `
      Generate html markup for the following text, use h1, h2, h3, h4, p, ul, li tags 
      ${form.values["title"]}
      
      ${form.values["talkingPoints"]}
      `))
    setLoading(false)
  }

  const authenticated = useAuthenticated()

  const theme = useMantineTheme();

  const navigate = useNavigate();

  return (
    <>
      {loading && <div style={{
        position: "fixed",
        height: "100%",
        width: "100%",
        zIndex: 400,
        top: 0,
        left: 0
      }}>
          <LoadingOverlay visible={loading}></LoadingOverlay>
      </div>}
      <form onSubmit={form.onSubmit(handleFormSubmit)}>
        <Stepper active={step} breakpoint="sm">
          <Stepper.Step label="First step" description="Come up with a title">
            <Title mt={"10%"} order={2} p={"md"} align={"center"}>Let's get started with a title</Title>
            <Stack spacing={"sm"}>
              <Flex gap={"sm"}>
                <TextInput
                  inputWrapperOrder={['label', 'input', 'description', 'error']} sx={{
                  flexGrow: 1
                }} placeholder={"What do you want to write about? Put your title here..."}
                  {...form.getInputProps("title")}></TextInput>
                <Button rightIcon={<IconArrowRight/>} onClick={() => {
                  !form.validate().hasErrors && setStep(1)
                }}>Let's go!</Button>
              </Flex>
              <Text pt={"lg"} size={"sm"} align={"center"} color={"dimmed"}>
                Stuck? Pick one of the <Link onClick={open1} to={""}>examples</Link>, or can help you with <Link
                onClick={open}
                to={""}>generating</Link> the title.
              </Text>
            </Stack>
            <IdeaGenerator/>
            <Modal title={"Essay title examples"} opened={opened1} onClose={close1} withCloseButton>
              <Stack>
                {examples.map((x, y) => (
                  <Paper withBorder key={y} sx={{ cursor: "pointer" }} onClick={() => {
                    form.setValues({ ...x });
                    close1()
                  }}
                         p={"xs"}><Text size={"sm"}>{x.headline}</Text></Paper>))}
              </Stack>
            </Modal>

          </Stepper.Step>
          <Stepper.Step label="Second step" description="Generate an outline">
            <Title order={2} p={"md"} align={"center"}>Generate an outline</Title>
            <Stack spacing={"sm"}>
              <Select {...form.getInputProps("tone")} label={"Tone"} data={tones}></Select>
              <NumberInput {...form.getInputProps("wordCount")} label={"Essay length in words"}></NumberInput>
              {form.values["outline"] && <Textarea label={"Outline"} autosize {...form.getInputProps('outline')} />}
              <Group>
                <Button variant={"outline"} leftIcon={<IconArrowLeft/>} onClick={() => {
                  form.setFieldValue("wordCount", 1000)
                  setStep(0)
                }}>Go back</Button>
                <Button variant={form.values["outline"] ? "outline" : "filled"}
                        type={"submit"}>{form.values["outline"] ? "Generate another" : "Generate"} outline</Button>
                {form.values["outline"] &&
                    <Button rightIcon={<IconArrowRight/>} onClick={() => setStep(2)}>Use this outline</Button>}
              </Group>
            </Stack>
          </Stepper.Step>
          <Stepper.Step label="Third step" description="Generate talking points">
            <Title order={2} p={"md"} align={"center"}>Generate talking points</Title>
            <Stack spacing={"sm"}>
              <Select {...form.getInputProps("style")} label={"Style"} data={styles}></Select>
              {form.values["talkingPoints"] &&
                  <div style={{ position: "relative" }}>
                    {!authenticated && <Overlay
                        gradient={`linear-gradient(180deg, rgba(255,255,255,0) 0, ${theme.colors.gray[0]} 60%)`}
                    />}
                      <Textarea
                          label={"Talking Points"}
                          minRows={authenticated ? null : "10"}
                          autosize={authenticated} {...form.getInputProps('talkingPoints')} />
                  </div>}
              <Group>
                <Button variant={"outline"} leftIcon={<IconArrowLeft/>} onClick={() => {
                  setStep(1)
                }}>Go back</Button>
                <SignedOut>
                  {!form.values["talkingPoints"] &&
                      <Button variant={"filled"} type={"submit"}>Generate talking points</Button>}
                  {form.values["talkingPoints"] &&
                      <Button variant={"gradient"} onClick={() => navigate('/sign-up')}>Sign up to continue</Button>}
                </SignedOut>
                <SignedIn>
                  <Button variant={form.values["talkingPoints"] ? "outline" : "filled"}
                          type={"submit"}>{form.values["talkingPoints"] ? "Regenerate" : "Generate"} talking
                    points</Button>
                  {form.values["talkingPoints"] &&
                      <Button rightIcon={<IconArrowRight/>} onClick={async () => {
                        await generateMarkup()
                        setStep(3)
                      }}>Continue with these talking
                          points</Button>}
                </SignedIn>
              </Group>
            </Stack>
          </Stepper.Step>
          <Stepper.Step label="Final step" description="Wrap things up and polish">
            <Stack>
              <Title order={2} p={"md"} align={"center"}>Final step, edit the text to your linking.</Title>
              <RichTextEditor onChange={(e) => setMarkup(e)} value={markup}></RichTextEditor>
              <Group>
                <Button variant={"outline"} leftIcon={<IconArrowLeft/>} onClick={() => {
                  setStep(1)
                }}>Go back</Button>
                <Button rightIcon={<IconArrowRight/>} onClick={() => setStep(4)}>Looks good!</Button>
              </Group>
            </Stack>
          </Stepper.Step>
          <Stepper.Completed>
            <Title order={2} p={"md"} align={"center"}>Congrats!<br/>Your essay is complete and ready to
              download.</Title>
            <Stack align={"center"}>
              <Button mt={"lg"} size={"lg"} ml={"auto"} mr={"auto"} onClick={() => {
                const doc = new jsPDF.jsPDF()
                doc.html(markup, {
                  margin: [10, 10, 10, 10],
                  autoPaging: 'text',
                  x: 0,
                  y: 0,
                  width: 190, //target width in the PDF document
                  windowWidth: 675, //window width in CSS pixels
                  callback: (doc) => {
                    doc.save(form.values["title"].replace(/ /g, "_") + ".pdf")
                  }
                })

              }} rightIcon={<IconDownload/>}>Download PDF</Button>
              <Button rightIcon={<IconRotateClockwise/>} size={"sm"} variant={"subtle"} onClick={() => {
                setStep(0)
                form.reset()
              }}>or start over </Button>
            </Stack>
          </Stepper.Completed>
        </Stepper>
      </form>
      <Outlet/>
    </>
  );
}

export default App;
